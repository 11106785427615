import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VLayout,[_c(VRow,{style:({ 'margin-left': _vm.marginLeft, 'margin-right': _vm.marginRight }),attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"5"}},[_c(VCard,{staticClass:"mt-12 mx-auto py-4 elevation-6"},[_c(VCard,{staticClass:"mx-auto my-n10 elevation-6",attrs:{"color":"blue-grey darken-4","max-width":"calc(100% - 32px)"}},[_c(VCardTitle,{staticClass:"font-weight-light white--text center"},[_vm._v(" "+_vm._s(_vm.$t(_vm.head404))+" ")])],1),_c(VCardText,{staticClass:"pt-12 pb-0"},[_c('div',{staticClass:"title font-weight-light mb-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.text404))+" ")]),_c('div',{staticClass:"subheading font-weight-light grey--text"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t(_vm.text2404))+" ")])]),_c(VDivider,{staticClass:"my-2"}),_c(VBtn,{attrs:{"to":"/","color":"primary","ripple":"","block":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.btn404))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }