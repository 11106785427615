<template>
  <v-container class="fill-height" fluid>
    <v-layout>
      <v-row
        align="center"
        justify="center"
        :style="{ 'margin-left': marginLeft, 'margin-right': marginRight }"
      >
        <v-col cols="12" sm="8" md="5">
          <v-card class="mt-12 mx-auto py-4 elevation-6">
            <v-card
              color="blue-grey darken-4"
              class="mx-auto my-n10 elevation-6"
              max-width="calc(100% - 32px)"
            >
              <v-card-title class="font-weight-light white--text center">
                {{ $t(head404) }}
              </v-card-title>
            </v-card>
            <v-card-text class="pt-12 pb-0">
              <div class="title font-weight-light mb-2">
                {{ $t(text404) }}
              </div>
              <div class="subheading font-weight-light grey--text">
                <p>
                  {{ $t(text2404) }}
                </p>
              </div>
              <v-divider class="my-2"></v-divider>
              <v-btn :to="`/`" color="primary" ripple block>
                {{ $t(btn404) }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import confCore from "../conf/confCore";

export default {
  name: "FourOhFour",
  data() {
    return {
      head404: confCore.head404,
      text404: confCore.text404,
      text2404: confCore.text2404,
      btn404: confCore.btn404
    };
  },
  computed: {
    marginLeft() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0px";
        default:
          return "-12px";
      }
    },
    marginRight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0px";
        default:
          return "-12px";
      }
    }
  }
};
</script>

<style scoped></style>
